









import { Component, Vue } from 'vue-property-decorator'

const AppProps = Vue.extend({
  props: {
    identifier: String
  }
})
@Component({
  components: {}
})
export default class CrapsPayout extends AppProps {
}
