

















































import { Component, Vue } from 'vue-property-decorator'
import CrapsBet from '@/components/macros/CrapsBet.vue'

const AppProps = Vue.extend({
  props: ['num', 'text']
})
@Component({
  components: {
    CrapsBet
  }
})
export default class CrapsNumby extends AppProps {
}
