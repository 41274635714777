import { render, staticRenderFns } from "./CrapsPayout.vue?vue&type=template&id=21fcaf8a&scoped=true&"
import script from "./CrapsPayout.vue?vue&type=script&lang=ts&"
export * from "./CrapsPayout.vue?vue&type=script&lang=ts&"
import style0 from "./CrapsPayout.vue?vue&type=style&index=0&id=21fcaf8a&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "21fcaf8a",
  null
  
)

export default component.exports