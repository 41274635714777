













import { Component, Vue } from 'vue-property-decorator'

const AppProps = Vue.extend({
  props: ['identifier']
})
@Component({
  components: {}
})
export default class CrapsBigDie extends AppProps {
}
