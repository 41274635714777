

































































































































































































































































































































































































































































































































































































































































































































import CrapsBet from "@/components/macros/CrapsBet.vue";
import CrapsBigDie from "@/components/macros/CrapsBigDie.vue";
import CrapsNumby from "@/components/macros/CrapsNumby.vue";
import CrapsPayout from "@/components/macros/CrapsPayout.vue";

import { Component, Vue } from "vue-property-decorator";
import FastGameMenu from "@/components/menus/FastGameMenu.vue";
import Common from "@/mixins/Common.vue";
import { namespace } from "vuex-class";
import axios from "axios";

const UIMutation = namespace("ui").Mutation;
const UIGetter = namespace('ui').Getter;
const SettingsGetter = namespace("settings").Getter;
const AccountGetter = namespace('account').Getter

const AppProps = Vue.extend({
  props: {},
  mixins: [Common],
  data: () => {
    return {
      progressive_bet: 0,
      isAccountLoaded: false
    };
  },
  methods: {
    async getRuleSet() {
      try {
        let res = await axios.get("/api/craps/ruleset");
        if (res.data.result) {
          return res.data.result;
        } else {
          throw new Error("Invalid craps ruleset response");
        }
      } catch (e) {
        this.$router.push('maintenance')
        console.log("Error trying to get the craps ruleset");
        return e;
      }
    },
    async getSeed() {
      try {
        let res = await axios.post("/api/craps/seed");
        if (res.data.server_seed_hash) {
          return res.data.server_seed_hash;
        } else {
          throw new Error("Invalid craps seed response");
        }
      } catch (e) {
        console.log("Error getting craps seed");
        return e;
      }
    },
    async initGame() {
      this.isAccountLoaded = true;
      try {
        this.initializeGameScale();
        //@ts-ignore
        await this.$loadScript("/js/legacy/craps.js");
        let ruleset = await this.getRuleSet();
        // this.progressive_bet = ruleset.valid_credit_sizes[ruleset.valid_credit_sizes.length - 1]
        this.progressive_bet = 0
        let starting_server_seed_hash = await this.getSeed();
        // @ts-ignore
        // eslint-disable-next-line no-undef
        init_craps(
                "craps",
                "craps",
                "craps",
                starting_server_seed_hash,
                [],
                [],
                [],
                ruleset,
                this.progressive_bet,
                []
        );
      } catch (e) {
        console.log("Error", e);
      }
    }
  },
  metaInfo: {
    meta: [{
      vmid: 'description',
      name: 'description',
      content: `Play Exclusive Dice Game Craps Online with Bitcoin, Win Big Jackpots & Cashout Instantly at the best Bitcoin Games Casinos`
    },
    {
      vmid: 'title',
      name: 'title',
      content: `Craps`
    },
    {
      vmid: 'keywords',
      name: 'keywords',
      content: `bitcoin casino, btc casino, bch casino, crypto casino, bitcoin gambling, bitcoin casino no deposit, crypto casino no deposit, best bitcoin casino, bitcoin games`
    }],
  },
  watch: {
    vueAppKey: function () {
      this.initGame()
    }
  },
  created() {
    //@ts-ignore
    this.setFooterDisabled(true);
  },
  async mounted() {
    // note that its possible the usertoken is not yet available,
    // in this case the watcher will init craps game when the token
    // is available
    this.initGame();
  }
});
@Component({
  components: {
    FastGameMenu,
    CrapsBet,
    CrapsBigDie,
    CrapsNumby,
    CrapsPayout
  }
})
export default class Craps extends AppProps {
  @UIMutation("setFooterDisabled") setFooterDisabled;
  @SettingsGetter getPlatformCurrency;
  @AccountGetter userToken
  @UIGetter vueAppKey
}
